import BaseRecommendApiRequestDto from "./BaseRecommendApiRequestDto";

export default class GetSoldRequestDto extends BaseRecommendApiRequestDto {
	//
	constructor(showRecommendParams) {
		super();
		
		for (const key in this) {
			this[key] = showRecommendParams[key];
		}
	}
}
