export default class EtmParams {
	//
	static get(key) {
		//
		let element = document.querySelector(`meta[property="etm:${key}"]`);
		if (!element) {
			element = document.querySelector(`meta[property="meta:${key}"]`);
		}
		if (!element) {
			return {};
		}

		let content = element.content;
		if (content === undefined) {
			return {};
		}

		try {
			return JSON.parse(content);
		} catch (ex) {
			return content;
		}
	}
}
