import Logger from "../utils/Logger";

export default class QueryParams {

	static getParams(url) {
		let queryParams = {};
		try {
			// URLオブジェクトを作成
			const urlObj = new URL(url);

			// URLオブジェクトからクエリパラメータを取得
			const searchParams = new URLSearchParams(urlObj.search);

			// クエリパラメータオブジェクト
			queryParams = Object.fromEntries(searchParams.entries());
		} catch (ex) {
			Logger.LogError(ex);
		}

		return queryParams;
	}

	static splitLandingKey(landingkey) {
		let splitedkey = {};

		if(landingkey == undefined){
			return splitedkey;
		}

		const parts = landingkey.split('_');
		if (parts.length !== 3) {
			return splitedkey;
		}

		const [key, recommendtype, seq] = parts;
		splitedkey["landingkey"] = key;
		splitedkey["recommendtype"] = recommendtype;
		splitedkey["seq"] = seq;

		return splitedkey;
	}

	static validateParams(params) {
		let validatedParams = {};
		if(params == undefined){
			return validatedParams;
		}

		if(Object.keys(params).length == 0){
			return validatedParams;
		}

		for (const [key, value] of Object.entries(params)) {
			switch (key) {
				case 'seq':
					var parsedValue = Number(value);
					if(isNaN(parsedValue)){
						Logger.LogError(`有効な値ではありません、${key}: ${value}`);
						continue;
					}
					if (parsedValue < 1) {
						Logger.LogError(`有効な値ではありません、${key}: ${value}`);
						continue;
					}
					validatedParams[key] = parsedValue;
					break;
				default:
					validatedParams[key] = value;
					break;
			}
		}

		return validatedParams;
	}
}
