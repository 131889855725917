import BaseRecommendApiRequestDto from "./BaseRecommendApiRequestDto";

export default class GetPvHistoryRequestDto extends BaseRecommendApiRequestDto {
	//
	goodsCode = undefined;

	constructor(showRecommendParams) {
		super();

		for (const key in this) {
			this[key] = showRecommendParams[key];
		}
	}
}
