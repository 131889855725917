import { inject } from "vue/dist/vue.esm-bundler.js";

export default {
	//
	props: ["rating", "reviewCount", "url"],
	template: `<div v-html="showRevicoStar()"></div>`,
	setup(props) {
		//
		const revicoTemplate = inject("revicoTemplate");

		function showRevicoStar() {
			//
			if (!revicoTemplate) {
				return "";
			}

			let reviewCount = props.reviewCount || 0;
			let tmpl = revicoTemplate;

			const star = `<div class="starability-result" data-rating="${rate(Number(props.rating))}"></div>`;
			tmpl = tmpl.replace(/\$\{StarRating\}/g, star);
			tmpl = tmpl.replace(/\$\{ReviewUrl\}/g, props.url + "#revico-comment"); //コメントへのアンカー付き
			tmpl = tmpl.replace(/\$\{ReviewCount\}/g, reviewCount.toString());
			tmpl = tmpl.replace(/\$\{ReviewAvg\}/g, new Number(props.rating).toFixed(1));

			return tmpl;
		}

		function rate(rate) {
			const rates = (rate + "").split(".");
			const seisu = Number(rates[0]);
			const shosu = Number(rates[1] || "0");

			if (shosu >= 8) {
				return seisu + 1;
			} else if (shosu < 3) {
				return seisu;
			} else if (3 <= shosu && shosu < 8) {
				return seisu + 0.5;
			}
		}

		return {
			showRevicoStar,
		};
	},
};
