import Logger from "../utils/Logger";

export default class PageEmbeddedParams {
	//
	static value = undefined;

	static init() {
		//
		let elements = document.querySelectorAll(`script[type="application/l-recommend"]`);
		let peParams = {};

		for (let element of elements) {
			try {
				let json = JSON.parse(element.textContent);
				Object.assign(peParams, json);
			} catch (ex) {
				Logger.LogError(ex);
			}
		}

		PageEmbeddedParams.value = peParams;
	}
}
