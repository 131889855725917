export default class Cookie {
	// cookie管理

	static set(key, value, { expires, path } = {}) {
		//
		let cookie = key + "=" + encodeURIComponent(value);

		cookie += "; samesite=strict";
		cookie += "; secure";

		if (expires instanceof Date) {
			cookie += "; expires=" + expires.toUTCString();
		}

		if (path) {
			cookie += "; path=" + path;
		} else {
			cookie += "; path=/";
		}

		document.cookie = cookie;
	}

	static get(key) {
		return Cookie.#getCookies()[key];
	}

	static remove(key) {
		Cookie.set(key, "", new Date("2000/01/01"));
	}

	static #getCookies() {
		const cookies = {};
		for (let cookie of document.cookie.split("; ")) {
			let key = cookie.split("=")[0];
			let value = cookie.substring(key.length + 1);
			cookies[key] = decodeURIComponent(value);
		}
		return cookies;
	}
}
