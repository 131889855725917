import Cookie from "./Cookie";
import LocalStorageCache from "./LocalStorageCache";

export default class StorageCache {
	// Storageキャッシュ管理

	static keys = {
		userId: "l-recommend-uid",
		sessionId: "l-recommend-sid",
		goodsHistory: "l-recommend-his",
		contentsHistory: "l-recommend-his-c",
		goodsPvHistory: "l-recommend-his-goods",
		contentsPvHistory: "l-recommend-his-contents",
		goodsHistoryEnabled: "l-recommend-his-goods-enable",
		contentsHistoryEnabled: "l-recommend-his-contents-enable",
		stgMode: "l-recommend-stg",
	};

	static sources = {
		auto: "auto",
		localStorage: "localStorage",
		cookie: "cookie",
	};

	constructor(source) {
		//
		this.available = true;

		switch (source) {
			case StorageCache.sources.localStorage:
				if (!LocalStorageCache.isAvailable()) {
					this.available = false;
				}
				break;
			case StorageCache.sources.cookie:
				break;
			default: // autoの場合はここでどちらを使うか決める
				source = StorageCache.sources.localStorage;
				if (!LocalStorageCache.isAvailable()) {
					source = StorageCache.sources.cookie;
				}
		}

		this.source = source;
	}

	get(key) {
		if (!this.available) {
			return undefined;
		}
		let result = undefined;
		switch (this.source) {
			case StorageCache.sources.localStorage:
				result = LocalStorageCache.get(key);
				break;
			case StorageCache.sources.cookie:
				result = Cookie.get(key);
				break;
		}
		return result;
	}

	set(key, value, { expires, path } = {}) {
		if (!this.available) {
			return;
		}
		switch (this.source) {
			case StorageCache.sources.localStorage:
				LocalStorageCache.set(key, value, expires);
				break;
			case StorageCache.sources.cookie:
				Cookie.set(key, value, expires, path);
				break;
		}
	}

	remove(key) {
		if (!this.available) {
			return;
		}
		switch (this.source) {
			case StorageCache.sources.localStorage:
				LocalStorageCache.remove(key);
				break;
			case StorageCache.sources.cookie:
				Cookie.remove(key);
				break;
		}
	}
}
