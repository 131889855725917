import ApiRequest from "../api/ApiRequest";

export default class Logger {
    //
    static async LogError(err) {
        console.error(err);
        if (err instanceof Error) {
            err = `${err.message}\r\n${err.stack}`;
        }
        await ApiRequest.sendLogToNewRelic(err, "Error");
    }

    static async LogWarning(err) {
        console.warn(err);
        if (err instanceof Error) {
            err = `${err.message}\r\n${err.stack}`;
        }
        await ApiRequest.sendLogToNewRelic(err, "Warning");
    }
}