import { inject, ref, onMounted } from "vue/dist/vue.esm-bundler.js";
import StorageCache from "../utils/StorageCache";

export default {
	//
	template: `
	<div>
		<a href="#" @click.prevent.stop="enableHistory" v-if="!historyEnabled">履歴を残す</a>
		<a href="#" @click.prevent.stop="disableHistory" v-else>履歴を残さない</a>
	</div>
	`,
	setup(_, { emit }) {
		//
		let goodsList = inject("goodsList");
		let typeCode = inject("typeCode");
		const historyEnabled = ref(true);
		const storage = new StorageCache(StorageCache.sources.auto);

		onMounted(() => {
			const storedValue = storage.get(typeCode === "Goods" ? StorageCache.keys.goodsHistoryEnabled : StorageCache.keys.contentsHistoryEnabled);
			if (storedValue == "false") {
				historyEnabled.value = false;
				emit("change", historyEnabled.value);
			}
		});

		function enableHistory() {
			typeCode === "Goods" ? storage.set(StorageCache.keys.goodsHistoryEnabled, "true") : storage.set(StorageCache.keys.contentsHistoryEnabled, "true");
			historyEnabled.value = true;
			emit("change", historyEnabled.value);
		}

		function disableHistory() {
			if (typeCode === "Goods") {
				storage.set(StorageCache.keys.goodsHistoryEnabled, "false");
				storage.remove(StorageCache.keys.goodsPvHistory);
			} else {
				storage.set(StorageCache.keys.contentsHistoryEnabled, "false");
				storage.remove(StorageCache.keys.contentsPvHistory);
			}
			historyEnabled.value = false;
			emit("change", historyEnabled.value);
			if (goodsList && Array.isArray(goodsList)) {
				goodsList.splice(0, goodsList.length);
			}
		}

		return {
			historyEnabled,
			enableHistory,
			disableHistory,
		};
	},
};
