export default class LocalStorageCache {
	// LocalStorage管理

	static #available;

	static get(key) {
		let item = localStorage.getItem(key);
		if (!item) {
			return null;
		}
		let expires = new Date(item.substring(0, 24));
		if (isNaN(expires) || expires < new Date()) {
			LocalStorageCache.remove(key);
			return null;
		}
		let value = JSON.parse(item.substring(25));
		return value;
	}

	static set(key, value, { expires } = {}) {
		if (!(expires instanceof Date)) {
			expires = new Date("2100/01/01");
		}
		let item = `${expires.toISOString()} ${JSON.stringify(value)}`;
		localStorage.setItem(key, item);
	}

	static remove(key) {
		localStorage.removeItem(key);
	}

	static isAvailable(){
		if (LocalStorageCache.#available === undefined) {
			LocalStorageCache.#available = false;
			try {
				if (window.localStorage !== undefined) {
					const key = new Date() -1 + "";
					localStorage.setItem(key, "1");
					if (localStorage.getItem(key) === "1") {
						localStorage.removeItem(key);
						LocalStorageCache.#available = true;
					}
				}
			} catch (ex) {
			}
		}
		return LocalStorageCache.#available;
	}
}
