import LightningRecommend from "./LightningRecommend";
import PageEmbeddedParams from "./params/PageEmbeddedParams";
import LifecycleEvent from "./utils/LifecycleEvent";

function main() {
	//
	initEvents();

	PageEmbeddedParams.init();
	LifecycleEvent.invoke("config", null, { params: PageEmbeddedParams.value });

	if (!botAccessControl()) {
		return;
	}

	LightningRecommend.load();
}

function botAccessControl() {
	//
	const botBlackList = [
		"bot",
		"crawler",
		"Y!J",
		"YahooSeeker",
		"Alchemy",
		"InfoSeek",
		"Baiduspider",
		"Mr.Nakasone(http://www.hikaku.com)",
		"WWWC/",
		"Binetx/1.0",
		"libwww-perl/",
		"Java/1.5.0_01",
		"Becky! BlogReader/1.2",
		"GAZAgent/0.1 libwww-perl/5.64",
		"Snoopy v1.01",
		"WakameCrawler/",
		"ichiro/3.0 (http://help.goo.ne.jp/door/crawler.html)",
		"Slurp",
		"check_http",
	];
	const botWhiteList = ["Googlebot", "Storebot-Google", "Google-InspectionTool", "bingbot"];

	if (acceptUserAgent(botWhiteList, botBlackList) === false) {
		return false;
	}

	return true;
}

function initEvents() {
	//
	let LightningRecommendEvent = window["LightningRecommendEvent"] || {};
	let LightningRecommendEventV2 = window["LightningRecommendEventV2"] || [];

	let events = [LightningRecommendEvent].concat(LightningRecommendEventV2);

	for (let event of events) {
		for (let type in event) {
			let listener = event[type];
			if (typeof listener == "function") {
				LightningRecommend.addEventListener(type, listener);
			}
		}
	}
}

function acceptUserAgent(botWhiteList, botBlackList) {
	const settingParam = PageEmbeddedParams.value.setting || {};
	const allowSearchCrawler = settingParam.allowSearchCrawler;
	const userAgent = window.navigator.userAgent.toLowerCase();

	if (allowSearchCrawler === true) {
		for (const bot of botWhiteList) {
			if (userAgent.includes(bot.toLowerCase()) === true) {
				LightningRecommend.accessFromBot = true;
				return true;
			}
		}
	}
	for (const bot of botBlackList) {
		if (userAgent.includes(bot.toLowerCase()) == true) {
			LightningRecommend.accessFromBot = true;
			return false;
		}
	}

	LightningRecommend.accessFromBot = false;
	return true;
}

(function () {
	//
	if (window["LightningRecommend"]) {
		return;
	}

	window["LightningRecommend"] = LightningRecommend;

	if (document.readyState == "loading") {
		document.addEventListener("DOMContentLoaded", main);
	} else {
		main();
	}
})();
