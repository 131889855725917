import Env from "../config/Env";

export default class ApiUrl {
	//
	// レコメンドAPI
	static get getRecommend() {
		return ApiUrl.#createApiUrl("recommend");
	}

	// ランキングAPI
	static get getRanking() {
		return ApiUrl.#createApiUrl("ranking");
	}

	// 新着商品API
	static get getNewArrival() {
		return ApiUrl.#createApiUrl("newarrival");
	}

	// 今売れた商品API
	static get getSold() {
		return ApiUrl.#createApiUrl("sold");
	}

	// 関連コンテンツAPI
	static get getRelatedContents() {
		return ApiUrl.#createApiUrl("relatedcontents");
	}

	// 閲覧履歴API
	static get getPvHistory() {
		return ApiUrl.#createApiUrl("pvhistory");
	}

	// 似た商品API
	static get getSimilar() {
		return ApiUrl.#createApiUrl("similar");
	}

	// ルールベースAPI
	static get getRulebase() {
		return ApiUrl.#createApiUrl("rulebase");
	}

	// タグ情報取得API
	static get getTagsInfo() {
		return ApiUrl.#createApiUrl("tagsinfo");
	}

	// セッション発行・ユーザー登録API
	static get createSessionAndUser() {
		return ApiUrl.#createApiUrl("user/init");
	}

	// 属性データ収集API
	static get sendEcCustomer() {
		return ApiUrl.#createApiUrl("user/eccustomer");
	}

	// 表示履歴取得API（レコメンド表示履歴を送信する）
	static get sendRecommendOutputLog() {
		return ApiUrl.#createApiUrl("tracking/output");
	}

	// 商品履歴取得API（商品表示履歴を送信する）
	static get sendGoodsAccessLog() {
		return ApiUrl.#createApiUrl("tracking/goodsaccess");
	}

	// カート取得API（カート情報を送信する）
	static get sendCart() {
		return ApiUrl.#createApiUrl("tracking/cart");
	}

	// 注文取得API（注文情報を送信する）
	static get sendOrder() {
		return ApiUrl.#createApiUrl("tracking/order");
	}

	// クリックログ取得API
	static get getClickLog() {
		return ApiUrl.#createApiUrl("tracking/click");
	}

	static get sendLogToNewRelic() {
		return `${Env.apiBaseUrl}/log/v1`;
	}

	// TODO: ↓をどこかに書いておく。
	// テナントA：https://xxxxx.cloudfront.net/tenantA/api/getRecommend.json
	//            ↓
	//            https://xxxx.aks/tenantA/api/getRecommend.json
	//            ↓
	//            https://xxxx.aks/podA

	static #createApiUrl(path) {
		return `${Env.apiBaseUrl}/api/v1/${Env.tenantCode}/${path}`;
	}
}
