import ApiUrl from "../api/ApiUrl";
import ApiRequest from "../api/ApiRequest";
import PageEmbeddedParams from "../params/PageEmbeddedParams";
import StorageCache from "../utils/StorageCache";
import LifecycleEvent from "../utils/LifecycleEvent";

export default class Tracking {
	//

	// 表示内容取得
	static async trackRecommendOutput(userId, sessionId, tagsCode, goodsList) {
		if (goodsList.length == 0) {
			return;
		}
		let outputList = goodsList.map((goods, index) => {
			return {
				goodsCode: goods.goodsCode,
				seq: index + 1,
			};
		});
		await ApiRequest.sendRecommendOutputLog(userId, sessionId, tagsCode, outputList);
	}

	// 商品履歴取得
	static async trackGoodsAccess(userId, sessionId, goodsCode) {
		if (goodsCode){
			await ApiRequest.sendGoodsAccessLog(userId, sessionId, goodsCode);
		}
		else {
			let goodsAccessParams = PageEmbeddedParams.value.goodsAccess;
			if (!goodsAccessParams) {
				return;
			}
			await ApiRequest.sendGoodsAccessLog(userId, sessionId, goodsAccessParams.goodsCode);
		}
	}

	// コンテンツ履歴取得
	static async trackContentsAccess(userId, sessionId) {
		let contentsAccessParams = PageEmbeddedParams.value.contentsAccess;
		if (!contentsAccessParams) {
			return;
		}
		await ApiRequest.sendGoodsAccessLog(userId, sessionId, contentsAccessParams.contentsCode);
	}

	// カート取得
	static async trackCart(userId, sessionId) {
		let cartList = PageEmbeddedParams.value.cart;
		if (!cartList || cartList.length == 0) {
			return;
		}
		await ApiRequest.sendCart(userId, sessionId, cartList);
	}

	// 注文取得
	static async trackOrder(userId, sessionId) {
		let orderParams = PageEmbeddedParams.value.order;
		if (!orderParams) {
			return;
		}
		await ApiRequest.sendOrder(userId, sessionId, orderParams.customer, orderParams.orderId, orderParams.details);
	}

	// クリック履歴取得
	static trackClick(userId, sessionId, tagsCode, goodsCode, via, recommendType, index) {
		let goodsAccessParams = PageEmbeddedParams.value.goodsAccess;
		let contentsAccessParams = PageEmbeddedParams.value.contentsAccess;
		let sendClickUrl = `${ApiUrl.getClickLog}?userid=${userId}&sessionid=${sessionId}&goodscode=${goodsCode}&seq=${index + 1}`;
		if(tagsCode){
			sendClickUrl += `&tagscode=${tagsCode}`;
		}
		sendClickUrl += `&via=${via}`;
		if(recommendType){
			sendClickUrl += `&recommendtype=${recommendType}`;
		}
		if (goodsAccessParams && via != 'mail') {
			sendClickUrl += `&referrergoodscode=${goodsAccessParams.goodsCode}`;
		}
		if (contentsAccessParams) {
			sendClickUrl += `&referrercontentscode=${contentsAccessParams.contentsCode}`;
		}
		navigator.sendBeacon(sendClickUrl, "PING");
	}

	// あなたへのおすすめで使う直近に見た商品
	static setGoodsHistory() {
		let goodsAccessParams = PageEmbeddedParams.value.goodsAccess;
		if (!goodsAccessParams) {
			return;
		}
		let setGoodsHistoryEventObject = {
			goodsCode: goodsAccessParams.goodsCode,
		};
		LifecycleEvent.invoke("setGoodsHistory", null, setGoodsHistoryEventObject);
		if (setGoodsHistoryEventObject.goodsCode) {
			let storage = new StorageCache(StorageCache.sources.auto);
			storage.set(StorageCache.keys.goodsHistory, setGoodsHistoryEventObject.goodsCode);
		}
	}

	// あなたへのおすすめで使う直近に見たコンテンツ
	static setContentsHistory() {
		let contentsAccessParams = PageEmbeddedParams.value.contentsAccess;
		if (!contentsAccessParams) {
			return;
		}
		let setContentsHistoryEventObject = {
			contentsCode: contentsAccessParams.contentsCode,
		};
		LifecycleEvent.invoke("setContentsHistory", null, setContentsHistoryEventObject);
		if (setContentsHistoryEventObject.contentsCode) {
			let storage = new StorageCache(StorageCache.sources.auto);
			storage.set(StorageCache.keys.contentsHistory, setContentsHistoryEventObject.contentsCode);
		}
	}

	// 閲覧履歴で使う直近に見た商品
	static setGoodsPvHistory() {
		let storage = new StorageCache(StorageCache.sources.auto);
		let goodsHistoryEnabled = storage.get(StorageCache.keys.goodsHistoryEnabled);
		if (goodsHistoryEnabled != "false") {
			let goodsAccessParams = PageEmbeddedParams.value.goodsAccess;
			if (!goodsAccessParams) {
				return;
			}
			let setGoodsPvHistoryEventObject = {
				goodsCode: goodsAccessParams.goodsCode,
			};
			LifecycleEvent.invoke("setGoodsPvHistory", null, setGoodsPvHistoryEventObject);
			if (setGoodsPvHistoryEventObject.goodsCode) {
				let goodsPvHistory = storage.get(StorageCache.keys.goodsPvHistory);
				let goodsPvHistoryArray = goodsPvHistory ? goodsPvHistory.split(",") : [];
				let index = goodsPvHistoryArray.indexOf(setGoodsPvHistoryEventObject.goodsCode);
				if (index === 0) {
					return;
				} else if (index > 0) {
					goodsPvHistoryArray.splice(index, 1);
				}
				goodsPvHistoryArray.unshift(setGoodsPvHistoryEventObject.goodsCode);
				if (goodsPvHistoryArray.length > 20) {
					goodsPvHistoryArray.pop();
				}
				storage.set(StorageCache.keys.goodsPvHistory, goodsPvHistoryArray.join(","));
			}
		}
	}

	// 閲覧履歴で使う直近に見たコンテンツ
	static setContentsPvHistory() {
		let storage = new StorageCache(StorageCache.sources.auto);
		let contentsHistoryEnabled = storage.get(StorageCache.keys.contentsHistoryEnabled);
		if (contentsHistoryEnabled != "false") {
			let contentsAccessParams = PageEmbeddedParams.value.contentsAccess;
			if (!contentsAccessParams) {
				return;
			}
			let setContentsPvHistoryEventObject = {
				contentsCode: contentsAccessParams.contentsCode,
			};
			LifecycleEvent.invoke("setContentsPvHistory", null, setContentsPvHistoryEventObject);
			if (setContentsPvHistoryEventObject.contentsCode) {
				let contentsPvHistory = storage.get(StorageCache.keys.contentsPvHistory);
				let contentsPvHistoryArray = contentsPvHistory ? contentsPvHistory.split(",") : [];
				let index = contentsPvHistoryArray.indexOf(setContentsPvHistoryEventObject.contentsCode);
				if (index === 0) {
					return;
				} else if (index > 0) {
					contentsPvHistoryArray.splice(index, 1);
				}
				contentsPvHistoryArray.unshift(setContentsPvHistoryEventObject.contentsCode);
				if (contentsPvHistoryArray.length > 20) {
					contentsPvHistoryArray.pop();
				}
				storage.set(StorageCache.keys.contentsPvHistory, contentsPvHistoryArray.join(","));
			}
		}
	}
}
