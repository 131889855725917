import { createApp, reactive, onMounted, provide, ref } from "vue/dist/vue.esm-bundler.js";
import ApiRequest from "../api/ApiRequest";
import ShowRecommendParams from "../params/ShowRecommendParams";
import ShowRankingParams from "../params/ShowRankingParams";
import PageEmbeddedParams from "../params/PageEmbeddedParams";
import GetNewArrivalRequestDto from "../dto/request/GetNewArrivalRequestDto";
import GetRecommendRequestDto from "../dto/request/GetRecommendRequestDto";
import GetSoldRequestDto from "../dto/request/GetSoldRequestDto";
import GetRelatedContentsRequestDto from "../dto/request/GetRelatedContentsRequestDto";
import GetRankingRequestDto from "../dto/request/GetRankingRequestDto";
import GetPvHistoryRequestDto from "../dto/request/GetPvHistoryRequestDto";
import GetSimilarRequestDto from "../dto/request/GetSimilarRequestDto";
import GetRulebaseRequestDto from "../dto/request/GetRulebaseRequestDto";
import RevicoStarComponent from "../components/RevicoStarComponent";
import PvHistoryButtonComponent from "../components/PvHistoryButtonComponent";
import Logger from "../utils/Logger";
import LifecycleEvent from "../utils/LifecycleEvent";
import Tracking from "../utils/Tracking";

export default class View {
	//
	constructor(userId, sessionId, extendMethods) {
		this.userId = userId;
		this.sessionId = sessionId;
		this.extendMethods = extendMethods;
	}

	async showRecommend() {
		//
		let self = this;

		let settingParam = PageEmbeddedParams.value.setting || {};
		let defaultLoading = settingParam.defaultLoading == "lazy" ? "lazy" : "eager";

		let callCreateRecommendArea = async function (recommendElement) {
			return await self.#createRecommendArea(recommendElement);
		};

		let observer = this.#createIntersectionObserver(callCreateRecommendArea);
		let recommendElements = document.querySelectorAll("l-recommend");
		let tasks = [];

		for (let recommendElement of recommendElements) {
			//
			let loading = recommendElement.getAttribute("loading")?.toLowerCase();
			if (!["lazy", "eager"].includes(loading)) {
				loading = defaultLoading;
			}

			if (observer && loading == "lazy") {
				observer.observe(recommendElement);
			} else {
				let task = callCreateRecommendArea(recommendElement);
				tasks.push(task);
			}
		}

		return await Promise.all(tasks);
	}

	#createIntersectionObserver(intersectingCallback) {
		//
		if (typeof window["IntersectionObserver"] != "function") {
			return null;
		}

		let options = PageEmbeddedParams.value.options || {};

		let intersectionObserverOptions = {
			root: null,
			rootMargin: options.lazyLoadMargin || "1000px",
			threshold: 0.0,
		};

		let observer = new IntersectionObserver(function (entries, observer) {
			for (let entry of entries) {
				if (entry.isIntersecting) {
					let recommendElement = entry.target;
					observer.unobserve(recommendElement);
					intersectingCallback(recommendElement);
				}
			}
		}, intersectionObserverOptions);

		return observer;
	}

	async #createRecommendArea(recommendElement) {
		//
		const self = this;

		LifecycleEvent.invoke("beforeCreate", recommendElement, {
			tagsCode: recommendElement.dataset.tagscode,
		});

		// beforeCreateでtagsCodeを書き換えられるように、ここでtagsCodeを取得する
		let tagsCode = recommendElement.dataset.tagscode;

		let tagsData = await ApiRequest.getTagsInfo(tagsCode);
		if (!tagsData || typeof tagsData.template != "string") {
			Logger.LogWarning(`Invalid tags info. (${tagsCode})`);
			return;
		}
		if (tagsData.status === false) {
			return;
		}

		const template = document.createElement("template");
		template.innerHTML = tagsData.template;
		template.content.querySelectorAll("a").forEach((aTag) => {
			aTag.removeAttribute(":ping");
			aTag.setAttribute("v-on:click.capture", "sendClick($event, goods)");
		});

		let recommendData = await this.#getRecommendData(tagsCode, tagsData, recommendElement);

		if (!recommendData || !recommendData.response?.goodsList) {
			Logger.LogWarning(`Failed to get recommend data. (${tagsCode})`);
			return;
		}

		if (recommendData.isDisplay === false) {
			return;
		}

		let userInfo = PageEmbeddedParams.value.userInfo || {};
		if (userInfo.rank) {
			recommendData.response.goodsList.forEach((goods) => {
				let rankPrice = goods.rankPriceList?.find((rankPrice) => rankPrice.rank == userInfo.rank);
				if (rankPrice) {
					goods.price = rankPrice.price;
					goods.salePrice = rankPrice.salePrice;
				}
			});
		}

		const vueData = {
			title: tagsData.title,
			goods: null, // v-forの外側にaタグがある場合用
			wwwroot: window.ecblib?.sys?.wwwroot || "",
			cart_crsirefo_hidden: document.getElementsByName("crsirefo_hidden")[0]?.value || "",
		};
		for (const key in recommendData.values) {
			vueData[key] = recommendData.values[key];
		}

		const options = PageEmbeddedParams.value.options || {};
		const appElement = document.createDocumentFragment();
		const app = createApp({
			template: template.innerHTML,
			setup() {
				//
				const goodsList = reactive(recommendData.response.goodsList);
				const historyEnabled = ref(true);

				provide("goodsList", goodsList);
				provide("typeCode", tagsData.typeCode);
				provide("revicoTemplate", tagsData.revicoTemplate);

				function formatGoodsName(name, variationName1, variationName2) {
					return name + formatVariationName(variationName1, variationName2);
				}

				function formatVariationName(variationName1, variationName2) {
					const delimiter = options.variationNameDelimiter ?? "　";
					const enclosure = options.variationNameEnclosure ?? "（）";
					const enclosure1 = enclosure[0];
					const enclosure2 = enclosure[1] || enclosure1;
					const a = [];
					variationName1 && a.push(variationName1);
					variationName2 && a.push(variationName2);
					return a.length ? `${enclosure1}${a.join(delimiter)}${enclosure2}` : "";
				}

				function formatCurrency(value) {
					const prefix = options.currencyPrefix ?? "￥";
					const suffix = options.currencySuffix ?? "";
					return `${prefix}${(value + "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${suffix}`;
				}

				function onHistoryEnabledChange(newHistoryEnabled) {
					historyEnabled.value = newHistoryEnabled;
				}

				function sendClick(event, goods) {
					if (goods != null) {
						const index = goodsList.indexOf(goods);
						Tracking.trackClick(self.userId, self.sessionId, tagsCode, goods.goodsCode, "web", tagsData.type, index);
						LifecycleEvent.invoke("click", event.currentTarget, {
							tagsCode: tagsCode,
							goods: goods,
							originalEvent: event,
						});
					}
				}

				LifecycleEvent.invoke("create", recommendElement, {
					tagsCode: tagsCode,
					goodsList: goodsList,
				});

				onMounted(() => {
					//
					const $el = appElement.firstElementChild || appElement.firstChild || {};
					recommendElement.parentNode.replaceChild(appElement, recommendElement);

					if ($el.nodeType == Node.ELEMENT_NODE) {
						$el.dataset.tagscode = tagsCode;

						// ReviCoのCSSを読み込む
						if (tagsData.revicoCss) {
							const revicoStyle = document.createElement("style");
							revicoStyle.innerHTML = tagsData.revicoCss;
							document.body.appendChild(revicoStyle);
						}
					}

					LifecycleEvent.invoke("mount", $el, {
						tagsCode: tagsCode,
						goodsList: goodsList,
					});
				});

				return {
					...vueData,
					goodsList,
					historyEnabled,
					formatGoodsName,
					formatVariationName,
					formatCurrency,
					onHistoryEnabledChange,
					sendClick,
					...self.extendMethods,
				};
			},
		});

		app.component("revico-star", RevicoStarComponent);
		app.component("pvhistory-button", PvHistoryButtonComponent);
		app.mount(appElement);
	}

	async #getRecommendData(tagsCode, tagsData, recommendElement) {
		//
		const dataset = recommendElement.dataset;
		const recommendType = tagsData.type;

		if (["basic", "pv", "cv", "chance", "new", "sold", "relatedcontents", "pvhistory", "similar", "rulebase", "set"].includes(recommendType)) {
			const showRecommendParams = new ShowRecommendParams(tagsData, this.userId, dataset);

			LifecycleEvent.invoke("showParams", recommendElement, {
				tagsCode: tagsCode,
				params: showRecommendParams,
			});

			if (showRecommendParams.display === false) {
				return {
					isDisplay: showRecommendParams.display,
				};
			}

			if (!showRecommendParams.isValid()) {
				Logger.LogWarning(`Invalid parameters. (${dataset.tagscode})`);
				return null;
			}

			let requestDto, response;

			switch (recommendType) {
				case "basic":
				case "pv":
				case "cv":
				case "chance":
					requestDto = new GetRecommendRequestDto(showRecommendParams);
					if ((requestDto.type == "pv" || requestDto.type == "cv") && requestDto.goodsCode) {
						requestDto.userId = "00000000-0000-0000-0000-000000000000";
					}
					response = await ApiRequest.getRecommend(requestDto);
					break;
				case "new":
					requestDto = new GetNewArrivalRequestDto(showRecommendParams);
					response = await ApiRequest.getNewArrival(requestDto);
					break;
				case "sold":
					requestDto = new GetSoldRequestDto(showRecommendParams);
					response = await ApiRequest.getSold(requestDto);
					break;
				case "relatedcontents":
					requestDto = new GetRelatedContentsRequestDto(showRecommendParams);
					response = await ApiRequest.getRelatedContents(requestDto);
					break;
				case "pvhistory":
					requestDto = new GetPvHistoryRequestDto(showRecommendParams);
					response = await ApiRequest.getPvHistory(requestDto);
					break;
				case "similar":
					requestDto = new GetSimilarRequestDto(showRecommendParams);
					response = await ApiRequest.getSimilar(requestDto);
					break;
				case "rulebase":
				case "set":
					requestDto = new GetRulebaseRequestDto(showRecommendParams);
					response = await ApiRequest.getRulebase(requestDto);
					break;
			}

			return {
				response: response,
				values: showRecommendParams.values,
				isDisplay: showRecommendParams.display,
			};
		}

		if (["rpv", "rcv"].includes(recommendType)) {
			const showRankingParams = new ShowRankingParams(tagsData, dataset);

			LifecycleEvent.invoke("showParams", recommendElement, {
				tagsCode: tagsCode,
				params: showRankingParams,
			});

			if (showRankingParams.display === false) {
				return {
					isDisplay: showRankingParams.display,
				};
			}

			if (!showRankingParams.isValid()) {
				Logger.LogWarning(`Invalid parameters. (${dataset.tagscode})`);
				return null;
			}

			const requestDto = new GetRankingRequestDto(showRankingParams);
			const response = await ApiRequest.getRanking(requestDto);

			return {
				response: response,
				values: showRankingParams.values,
				isDisplay: showRankingParams.display,
			};
		}

		return null;
	}
}
