import ApiUrl from "./ApiUrl";
import Logger from "../utils/Logger";
import Env from "../config/Env";

export default class ApiRequest {
	//

	// レコメンドAPI
	static async getRecommend(showRecommendParams) {
		return await this.#sendApiRequest(
			ApiUrl.getRecommend,
			{
				method: "GET",
				params: showRecommendParams,
			},
			5000,
			true
		);
	}

	// ランキングAPI
	static async getRanking(showRankingParams) {
		return await this.#sendApiRequest(
			ApiUrl.getRanking,
			{
				method: "GET",
				params: showRankingParams,
			},
			5000,
			true
		);
	}

	// 新着商品API
	static async getNewArrival(showNewArrivalParams) {
		return await this.#sendApiRequest(
			ApiUrl.getNewArrival,
			{
				method: "GET",
				params: showNewArrivalParams,
			},
			5000,
			true
		);
	}

	// 今売れた商品API
	static async getSold(showSoldParams) {
		return await this.#sendApiRequest(
			ApiUrl.getSold,
			{
				method: "GET",
				params: showSoldParams,
			},
			5000,
			true
		);
	}

	// 関連コンテンツAPI
	static async getRelatedContents(showRelatedContentsParams) {
		return await this.#sendApiRequest(
			ApiUrl.getRelatedContents,
			{
				method: "GET",
				params: showRelatedContentsParams,
			},
			5000,
			true
		);
	}

	// 閲覧履歴API
	static async getPvHistory(showPvHistoryParams) {
		// 商品指定が無い場合はリクエストを送らない
		if (!showPvHistoryParams.goodsCode) {
			return { goodsList: [] };
		}
		return await this.#sendApiRequest(
			ApiUrl.getPvHistory,
			{
				method: "GET",
				params: showPvHistoryParams,
			},
			5000,
			true
		);
	}

	// 似た商品API
	static async getSimilar(showSimilarParams) {
		return await this.#sendApiRequest(
			ApiUrl.getSimilar,
			{
				method: "GET",
				params: showSimilarParams,
			},
			5000,
			true
		);
	}

	// ルールベースAPI
	static async getRulebase(showRulebaseParams) {
		return await this.#sendApiRequest(
			ApiUrl.getRulebase,
			{
				method: "GET",
				params: showRulebaseParams,
			},
			5000,
			true
		);
	}

	// タグ情報取得API
	static async getTagsInfo(tagsCode) {
		const params = {};
		if (Env.isStg === true) {
			params.isStg = true;
		}

		return await this.#sendApiRequest(
			ApiUrl.getTagsInfo + "/" + tagsCode,
			{
				method: "GET",
				params: params,
			},
			5000,
			true
		);
	}

	// セッション発行・ユーザー登録API
	static async createSessionAndUser(userId, sessionId) {
		return await this.#sendApiRequest(
			ApiUrl.createSessionAndUser,
			{
				method: "POST",
				data: {
					userId,
					sessionId,
				},
			},
			5000,
			true
		);
	}

	// 属性データ収集API
	static async sendEcCustomer(userId, sessionId, ecCustomer, onError) {
		return await this.#sendApiRequest(
			ApiUrl.sendEcCustomer,
			{
				method: "POST",
				data: {
					userId,
					sessionId,
					ecCustomer,
				},
			},
			5000,
			true,
			onError
		);
	}

	// 表示履歴取得API（レコメンド表示履歴を送信する）
	// 表示の後に実行すること！
	static async sendRecommendOutputLog(userId, sessionId, tagsCode, outputList) {
		return await this.#sendApiRequest(
			ApiUrl.sendRecommendOutputLog,
			{
				method: "POST",
				data: {
					userId,
					sessionId,
					tagsCode,
					outputList,
				},
			},
			5000,
			true
		);
	}

	// 商品履歴取得API（商品表示履歴を送信する）
	static async sendGoodsAccessLog(userId, sessionId, goodsCode) {
		return await this.#sendApiRequest(
			ApiUrl.sendGoodsAccessLog,
			{
				method: "POST",
				data: {
					userId,
					sessionId,
					goodsCode,
				},
			},
			5000,
			true
		);
	}

	// カート取得API（カート情報を送信する）
	static async sendCart(userId, sessionId, cartList) {
		return await this.#sendApiRequest(
			ApiUrl.sendCart,
			{
				method: "POST",
				data: {
					userId,
					sessionId,
					cartList,
				},
			},
			5000,
			true
		);
	}

	// 注文取得API（注文情報を送信する）
	static async sendOrder(userId, sessionId, ecCustomer, orderId, details) {
		return await this.#sendApiRequest(
			ApiUrl.sendOrder,
			{
				method: "POST",
				data: {
					userId,
					sessionId,
					ecCustomer,
					orderId,
					details,
				},
			},
			5000,
			true
		);
	}

	// NewRelicログ送信API
	static async sendLogToNewRelic(message, level) {
		const labels = {
			app: "AIRecommend.main.js",
			tenant: Env.tenantCode,
		};
		let url = location.href;
		// WAFでlocalhostが弾かれないようにする対応
		const hostName = location.hostname;
		if (["localhost"].includes(hostName)) {
			const base64HostName = btoa(hostName);
			url = url.replace(hostName, base64HostName);
			message = message.replaceAll(hostName, base64HostName);
		}
		return await this.#sendApiRequest(
			ApiUrl.sendLogToNewRelic,
			{
				method: "POST",
				data: {
					message,
					level,
					labels,
					url,
					agent: window.navigator.userAgent,
				},
			},
			5000,
			false
		);
	}

	static async #sendApiRequest(url, fetchConfig, timeout, isLoggingOnError, onError) {
		//
		if (fetchConfig.params) {
			for (const key in fetchConfig.params) {
				if (fetchConfig.params[key] === undefined) {
					delete fetchConfig.params[key];
				}
			}

			const queryParams = new URLSearchParams(fetchConfig.params);
			url = url + "?" + queryParams;
		}

		if (fetchConfig.method === "POST") {
			fetchConfig.headers = {
				"Content-Type": "application/json",
			};

			fetchConfig.body = JSON.stringify(fetchConfig.data);
		}

		const abortController = new AbortController();
		setTimeout(() => {
			abortController.abort();
		}, timeout);
		fetchConfig.signal = abortController.signal;

		return await fetch(url, fetchConfig)
			.then((response) => {
				if (response.status >= 400 && response.status < 600) {
					return response.json().then((error) => {
						let message = error.message || error;
						if (error.details instanceof Array) {
							message = error.details.map((d) => d.message).join(" | ");
						}
						const errorResponse = {
							status: response.status,
							data: error,
						};
						typeof onError == "function" && onError(errorResponse);
						throw new Error(message);
					});
				} else {
					return response;
				}
			})
			.then((response) => response.text())
			.then((responseJson) => {
				if (responseJson === "") {
					return null;
				}
				return JSON.parse(responseJson);
			})
			.catch((error) => {
				if (isLoggingOnError) {
					Logger.LogError(`${error.message}(${url})`);
				}
				return null;
			});
	}
}
