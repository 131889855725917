import BaseRecommendApiRequestDto from "./BaseRecommendApiRequestDto";

export default class GetRulebaseRequestDto extends BaseRecommendApiRequestDto {
	//
	goodsCode = undefined;
	tagsCode = undefined;
	fromPrice = undefined;
	toPrice = undefined;
	type = undefined;

	constructor(showRecommendParams) {
		super();

		for (const key in this) {
			this[key] = showRecommendParams[key];
		}
	}
}
