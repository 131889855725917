import PageEmbeddedParams from "./PageEmbeddedParams";
import StorageCache from "../utils/StorageCache";
import Logger from "../utils/Logger";

export default class ShowRecommendParams {
	//
	count = undefined;
	goodsCode = undefined;
	contentsCode = undefined;
	tagsCode = undefined;
	filterCode = undefined;
	inStockOnly = undefined;
	type = undefined;
	userId = undefined;
	excludeFilterCode = undefined;
	fromPrice = undefined;
	toPrice = undefined;
	typeCode = undefined;
	rank = undefined;
	values = {};
	display = true;
	fixedDisplayGoodsCode = undefined;
	filterCondition = undefined;
	excludeFilterCondition = undefined;

	constructor(tagsData, userId, dataset) {
		//
		const showParam = PageEmbeddedParams.value.show || {};
		const userInfoParam = PageEmbeddedParams.value.userInfo || {};
		const updateParams = showParam["tag_" + dataset.tagscode] || {};
		const storage = new StorageCache(StorageCache.sources.auto);
		const goodsHistory = storage.get(StorageCache.keys.goodsHistory);
		const contentsHistory = storage.get(StorageCache.keys.contentsHistory);
		const goodsPvHistory = storage.get(StorageCache.keys.goodsPvHistory);
		const contentsPvHistory = storage.get(StorageCache.keys.contentsPvHistory);

		for (const key in this) {
			const tagKey = key.toLowerCase();

			if (dataset[tagKey] !== undefined) {
				if (tagsData.typeCode === "Contents" && tagKey === "contentscode") {
					this.goodsCode = dataset[tagKey];
				} else {
					this[key] = dataset[tagKey];
				}
			} else if (updateParams[key] !== undefined) {
				if (tagsData.typeCode === "Contents" && key === "contentsCode") {
					this.goodsCode = updateParams[key];
				} else {
					this[key] = updateParams[key];
				}
			} else if (showParam[key] !== undefined) {
				if (tagsData.typeCode === "Contents" && key === "contentsCode") {
					this.goodsCode = showParam[key];
				} else {
					this[key] = showParam[key];
				}
			}
		}

		if (this.goodsCode) {
			this.goodsCode = this.goodsCode
				.split(",")
				.filter((e) => e)
				.join(",");
		}

		if (tagsData.type == "basic") {
			if (tagsData.typeCode === "Goods") {
				this.goodsCode = goodsHistory ? goodsHistory : "";
			} else {
				this.goodsCode = contentsHistory ? contentsHistory : "";
			}
		}

		if (tagsData.type == "pvhistory") {
			if (tagsData.typeCode === "Goods") {
				if (PageEmbeddedParams.value.goodsAccess?.goodsCode) {
					this.goodsCode = goodsPvHistory
						? goodsPvHistory
								.split(",")
								.filter((e) => e !== PageEmbeddedParams.value.goodsAccess.goodsCode)
								.join(",")
						: "";
				} else {
					this.goodsCode = goodsPvHistory ? goodsPvHistory : "";
				}
			} else {
				if (PageEmbeddedParams.value.contentsAccess?.contentsCode) {
					this.goodsCode = contentsPvHistory
						? contentsPvHistory
								.split(",")
								.filter((e) => e !== PageEmbeddedParams.value.contentsAccess.contentsCode)
								.join(",")
						: "";
				} else {
					this.goodsCode = contentsPvHistory ? contentsPvHistory : "";
				}
			}
		}

		this.tagsCode = dataset.tagscode;
		this.type = tagsData.type;
		this.count = tagsData.count;
		this.userId = userId;
		this.inStockOnly = tagsData.inStockOnly;
		this.typeCode = tagsData.typeCode;
		this.rank = userInfoParam.rank;
		this.fixedDisplayGoodsCode = tagsData.fixedDisplayGoodsCode;
	}

	isValid() {
		if (!this.type) {
			Logger.LogError("Type is none.");
			return false;
		}

		return true;
	}
}
