export default class UserData {
	guid = "";
	birth = "";
	gender = "";
	rank = "";
	salesCount = 0;
	userAttr1 = "";
	userAttr2 = "";
	userAttr3 = "";
	userAttr4 = "";
	userAttr5 = "";
}
