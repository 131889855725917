import Logger from "../utils/Logger";
import PageEmbeddedParams from "./PageEmbeddedParams";

export default class ShowRankingParams {
	//
	count = undefined;
	filterCode = undefined;
	inStockOnly = undefined;
	type = undefined;
	span = undefined;
	excludeFilterCode = undefined;
	typeCode = undefined;
	rank = undefined;
	values = {};
	display = true;
	filterCondition = undefined;
	excludeFilterCondition = undefined;

	constructor(tagsData, dataset) {
		//
		const rankingParam = PageEmbeddedParams.value.ranking || {};
		const userInfoParam = PageEmbeddedParams.value.userInfo || {};
		const updateParams = rankingParam["tag_" + dataset.tagscode] || {};

		for (const key in this) {
			const tagKey = key.toLowerCase();

			if (dataset[tagKey] !== undefined) {
				this[key] = dataset[tagKey];
			} else if (updateParams[key] !== undefined) {
				this[key] = updateParams[key];
			} else if (rankingParam[key] !== undefined) {
				this[key] = rankingParam[key];
			}
		}

		this.type = tagsData.type;
		this.count = tagsData.count;
		this.span = tagsData.span;
		this.inStockOnly = tagsData.inStockOnly;
		this.typeCode = tagsData.typeCode;
		this.rank = userInfoParam.rank;
	}

	isValid() {
		if (!this.type) {
			Logger.LogError("Type is none.");
			return false;
		}
		if (!this.span) {
			Logger.LogError("Span is none.");
			return false;
		}
		return true;
	}
}
