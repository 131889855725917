import Logger from "./Logger";

export default class LifecycleEvent {
	//
	static #events = {};
	static #supportedEventTypes = ["config", "beforeCreate", "showParams", "ready", "create", "mount", "load", "setGoodsHistory", "setContentsHistory", "setGoodsPvHistory", "setContentsPvHistory", "click"];

	static add(type, listener) {
		if (LifecycleEvent.#supportedEventTypes.indexOf(type) == -1) {
			Logger.LogWarning(`Ignore unsupported event type: ${type}`);
			return;
		}
		let listeners = LifecycleEvent.#events[type];
		if (!listeners) {
			listeners = LifecycleEvent.#events[type] = [];
		}
		listeners.push(listener);
	}

	static remove(type, listener) {
		let listeners = LifecycleEvent.#events[type];
		if (!listeners) {
			return;
		}
		LifecycleEvent.#events[type] = listeners.filter((x) => x != listener);
	}

	static invoke(type, element, evt) {
		let listeners = LifecycleEvent.#events[type];
		if (!listeners) {
			return;
		}
		for (let listener of listeners) {
			let result = undefined;
			try {
				result = listener.call(element, evt);
			} catch (ex) {
				Logger.LogError(ex);
			}
			if (result === false) {
				break;
			}
		}
	}
}
