export default class BaseRecommendApiRequestDto {
	//
	count = undefined;
	filterCode = undefined;
	excludeFilterCode = undefined;
	inStockOnly = undefined;
	typeCode = undefined;
	rank = undefined;
	filterCondition = undefined;
	excludeFilterCondition = undefined;
}
