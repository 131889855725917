import UserData from "../user/UserData";
import StorageCache from "../utils/StorageCache";
import ApiRequest from "../api/ApiRequest";
import PageEmbeddedParams from "../params/PageEmbeddedParams";
import EtmParams from "../params/EtmParams";

export default class UserLoader {
	//
	// ユーザー情報を取得・格納する
	static async load(retrySendEcCustomer = true) {
		//
		let storage = new StorageCache(StorageCache.sources.auto);
		let cookie = new StorageCache(StorageCache.sources.cookie);

		let userId = storage.get(StorageCache.keys.userId);
		let sessionId = cookie.get(StorageCache.keys.sessionId);

		if (!userId || !sessionId) {
			//
			let data = await ApiRequest.createSessionAndUser(userId, sessionId);
			if (!data) {
				return null;
			}

			userId = data.userId;
			sessionId = data.sessionId;

			storage.set(StorageCache.keys.userId, userId);
			cookie.set(StorageCache.keys.sessionId, sessionId);
		}

		let userData = UserLoader.#getUserData();
		if (userData.guid && userData.guid != "00000000-0000-0000-0000-000000000000") {
			const onError = (response) => {
				if (retrySendEcCustomer && response.status == 400 && response.data.details?.map((e) => e.target)?.includes("userId")) {
					storage.remove(StorageCache.keys.userId);
					UserLoader.load(false);
				}
			};

			// awaitしない
			ApiRequest.sendEcCustomer(userId, sessionId, userData, onError);
		}

		return { userId, sessionId };
	}

	// Bot用のユーザー情報を生成します
	static createBotUser() {
		return {
			userId: "00000000-0000-0000-0000-000000000000",
			sessionId: "00000000-0000-0000-0000-000000000000",
		};
	}

	static #getUserData() {
		//
		let userData = new UserData();

		UserLoader.#mapEtmParams(userData);

		let peParams = PageEmbeddedParams.value.customer;
		if (peParams) {
			for (let key in userData) {
				userData[key] = peParams[key];
			}
		}

		let birthMatches = /^(\d{4})/.exec(userData.birth);
		userData.birth = birthMatches ? `${birthMatches[1]}/01/01` : null;

		return userData;
	}

	static #mapEtmParams(userData) {
		//
		let etmCustomer = EtmParams.get("customer");

		if (!etmCustomer["l-recommend-guid"]) {
			return;
		}

		for (let key in userData) {
			let value = etmCustomer[`l-recommend-${key}`] || etmCustomer[key];
			if (value) {
				userData[key] = value;
			}
		}

		userData.gender = etmCustomer["sex"] || "";
		userData.salesCount = parseInt(etmCustomer["sales_count"]) || 0;
	}
}
