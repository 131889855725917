import Logger from "../utils/Logger";
import StorageCache from "../utils/StorageCache";

const config = require(CONFIG_FILE);

export default class Env {
	//
	static tenantCode = "";
	static isStg = false;

	static get apiBaseUrl() {
		return config.API_BASE_URL;
	}

	static init() {
		if (!Env.#loadTenant()) {
			return false;
		}
		Env.#initIsStg();
		return true;
	}

	static #loadTenant() {
		//
		let scriptTag = document.querySelector("script[id='l-recommend']");
		if (!scriptTag) {
			Logger.LogWarning('Recommend init error: Add id="l-recommend" to the base script tag.');
			return false;
		}

		Env.tenantCode = scriptTag.dataset.tenant;
		if (!Env.tenantCode) {
			Logger.LogWarning('Recommend init error: Add data-tenant="{tenantcode}" to the base script tag.');
			return false;
		}

		return true;
	}

	static #initIsStg() {
		//
		const searchParams = new URLSearchParams(window.location.search);
		const airecoStg = searchParams.get("airecostg")?.toLowerCase();
		const cookie = new StorageCache(StorageCache.sources.cookie);

		if (airecoStg == "on") {
			cookie.set(StorageCache.keys.stgMode, "1");
			Env.isStg = true;
		} else if (airecoStg !== undefined) {
			cookie.remove(StorageCache.keys.stgMode);
			Env.isStg = false;
		} else if (cookie.get(StorageCache.keys.stgMode) == "1") {
			Env.isStg = true;
		}
	}
}
